import { Location } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { HomeComponent } from '../../home/home.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SharedService } from 'src/app/services/shared.service';
@Component({
  selector: 'app-manage-reward',
  templateUrl: './manage-reward.component.html',
  styleUrls: ['./manage-reward.component.scss']
})
export class ManageRewardComponent implements OnInit, AfterContentChecked {
  selectedFile: any = null;
  imgUrl: any;
  edit: boolean = false
  reward_form!: FormGroup;
  submit: boolean = false
  reward: any
  regions: any
  categories: any
  temporel_reward: boolean = false
  @Output() onConfirm = new EventEmitter();
  social_media = [{ label: "Facebook", value: "facebook" }, { label: "Instagram", value: "instagram" }, { label: "Website", value: "website" }, { label: "Localisation", value: "location" }, { label: "Numéro de téléphone", value: "phone" }, { label: "Email", value: "email" }]
  loading: boolean = false

  type: any = [{ label: "Remise en valeur", value: "value_promo" }, { label: "Remise en pourcentage", value: "percent_promo" }, { label: "Livraison gratuite", value: "free_delivery" }, { label: "Objet physique", value: "physical_object" }, { label: "Cadeau par téléphone", value: "gift_by_phone" }, { label: "Cadeau par email", value: "gift_by_email" }, { label: "Partenariat", value: "partner_promo" }]

  configLangs: any[] = [];

  constructor(
    private messageService: MessageService,
    private ref: ChangeDetectorRef,
    private location: Location,
    private httpService: HttpService,
    private fb: FormBuilder,
    public home: HomeComponent,
    private datePipe: DatePipe,
    private appConfig: AppConfigService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  emailConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('type').value === 'percent_promo' || formControl.parent.get('type').value === 'value_promo') {
      return Validators.required(formControl);
    }
    return null;
  }
  ConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('all_regions').value === false) {
      return Validators.required(formControl);
    }
    return null;
  }
  partnerEmailValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('type').value === 'partner_promo') {
      return Validators.required(formControl);
    }
    return null;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params?.id !== "0") {
        this.edit = true
        this.getReward(params?.id)
      }
      else {
        this.edit = false
        this.loading = true
      }
    })
    this.getRegions()
    this.getCategories()
    this.configLangs = this.appConfig.configLangs;

    this.reward_form = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.maxLength(128)]),
      subtitle: new FormControl("", [Validators.maxLength(128)]),
      mini_description: new FormControl("", [Validators.maxLength(1024)]),
      description: new FormControl("", [Validators.maxLength(4096)]),
      activation_message: new FormControl("", [Validators.maxLength(4096)]),
      points: new FormControl(""),
      base_points: new FormControl(""),
      promo_until: new FormControl(""),
      type: new FormControl("", [Validators.required]),
      reward_category_id: new FormControl("", [Validators.required]),
      image: new FormControl("",),
      value: new FormControl("", this.emailConditionallyRequiredValidator),
      partner_email: new FormControl("", [Validators.email, this.partnerEmailValidator]),
      starts_at: new FormControl(""),
      expires_at: new FormControl(""),
      region_id: new FormControl("", this.ConditionallyRequiredValidator),
      all_regions: new FormControl(false),
      active: new FormControl(true, [Validators.required]),
      links: this.fb.array([]),

      translations: new FormArray([])
    }, {
      validators: [this.emailConditionallyRequiredValidator, this.partnerEmailValidator, this.ConditionallyRequiredValidator]
    });
    this.reward_form.setControl('links', this.fb.array([]));
    this.reward_form.get('type')?.valueChanges
      .subscribe(value => {
        this.reward_form.get('value')?.updateValueAndValidity();
      });
    this.reward_form.get('all_regions')?.valueChanges
      .subscribe(value => {
        this.reward_form.get('region_id')?.updateValueAndValidity();
      });
  }
  addLinkForm(): FormGroup {
    return this.fb.group({
      media: new FormControl("",),
      value: new FormControl("",),
    });
  }
  setExistingLinks(links: any): FormArray {
    const formArray = new FormArray([]);
    links.forEach((l: any) => {
      console.log(l);

      formArray.push(this.fb.group({
        media: l.media,
        value: l.value,
      }));
    });
    return formArray;
  }
  get formgroup() {
    return this.reward_form.get('links') as FormArray
  }
  addLink(): void {
    (<FormArray>this.reward_form.get('links')).push(this.addLinkForm());
  }
  deleteProduct(index: any) {
    (<FormArray>this.reward_form.get('links')).removeAt(index)
    console.log(this.reward_form);
  }
  get f() { return this.reward_form.controls; }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
      event.target.value = ""
    }
  }
  back() {
    this.location.back();
  }
  cancel() {
    this.edit = false
    this.submit = false
    this.reward_form.reset()
    this.selectedFile = null
  }
  getReward(id: any) {
    this.httpService.get("points_rewards/?filters=id:" + id).subscribe((data: any) => {
      if (data?.status === 200) {
        this.patchReward(data.body.data[0])
        this.loading = true
      }
    })
  }
  patchReward(reward: any) {
    this.edit = true
    this.reward = reward
    let links = this.reward.links.split('\n')
    const links_aray = links.map((item: any) => {
      const [media, value] = item.split(';');
      return { media: media?.trim(), value: value?.trim() };
    });
    console.log(links_aray);
    this.reward_form.setControl('links', this.setExistingLinks(links_aray));
    this.reward.promo_until ? this.temporel_reward = true : ""
    this.reward_form.patchValue({
      name: reward.name,
      subtitle: reward.subtitle,
      mini_description: reward.mini_description,
      description: reward.description,
      activation_message: reward.activation_message,
      points: reward.points,
      base_points: reward.base_points,
      type: reward.type,
      reward_category_id: reward.reward_category_id,
      image: reward.image,
      data: reward.data,
      value: reward.data.value,
      partner_email: reward.partner_email,
      starts_at: reward.starts_at ? new Date(reward.starts_at.date) : null,
      expires_at: reward.expires_at ? new Date(reward.expires_at.date) : null,
      promo_until: reward.promo_until ? new Date(reward.promo_until.date) : null,
      region_id: reward?.region?.id,
      all_regions: reward.all_regions === 1 ? true : false,
      active: reward.active ? true : false,
    })
    this.imgUrl = reward.image
    this.patchTranslationsData(this.reward_form, reward)
  }
  manageReward() {
    this.submit = true
    if (this.reward_form.invalid) {
      return;
    }
    const settings: any = {
      name: this.reward_form.value.name,
      subtitle: this.reward_form.value.subtitle,
      mini_description: this.reward_form.value.mini_description,
      description: this.reward_form.value.description,
      activation_message: this.reward_form.value.activation_message,
      base_points: this.reward_form.value.base_points,
      points: this.temporel_reward === true ? this.reward_form.value.points : "",
      type: this.reward_form.value.type,
      reward_category_id: this.reward_form.value.reward_category_id,
      active: this.reward_form.value.active === true ? 1 : 0,
      all_regions: this.reward_form.value.all_regions === true ? 1 : 0,
      region_id: this.reward_form.value.all_regions === true ? null : this.reward_form.value.region_id,
      links: this.reward_form.value.links.map((link: any) => `${link.media};${link.value}`).join('\n'),
    }
    this.temporel_reward === true ? settings.promo_until = this.datePipe.transform(this.reward_form.value.promo_until, 'YYYY-MM-dd HH:mm:ss') : settings.promo_until = "2000-01-01"
    this.reward_form.value.starts_at ? settings.starts_at = this.datePipe.transform(this.reward_form.value.starts_at, 'YYYY-MM-dd HH:mm:ss') : ""
    this.reward_form.value.expires_at ? settings.expires_at = this.datePipe.transform(this.reward_form.value.expires_at, 'YYYY-MM-dd HH:mm:ss') : ""
    this.reward_form.value.value ? settings.value = this.reward_form.value.value : ""
    this.reward_form.value.partner_email ? settings.partner_email = this.reward_form.value.partner_email : ""
    let translations: any = [...this.reward_form.value.translations];
    translations?.map((element: any) => {
      Object.keys(element || []).forEach((fst_key: any) => {
        let temp = <any>{}
        Object.keys(element[fst_key] || []).forEach((snd_key: any) => {
          temp[fst_key] = <any>{
            ...temp[fst_key],
            [snd_key]: element[fst_key][snd_key]
          }
          settings.translations = {
            ...settings.translations,
            ...temp
          }
        })
      })
    })
    if (this.edit) {
      this.httpService.post("points_reward/" + this.reward.id, settings).subscribe((data: any) => {
        if (data?.status === 200) {
          if (this.selectedFile) {
            const fd = new FormData();
            fd.append('image', this.selectedFile)
            this.httpService.post("points_reward/" + this.reward.id, fd).subscribe((data: any) => {
              if (data?.status === 200) {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Modification effectuée avec succès' });
              }
            })
          } else {
            this.messageService.add({ severity: 'success', summary: '', detail: 'Modification effectuée avec succès' });
          }
        }
        this.submit = false
        this.selectedFile = null
        Object.assign(this.reward, data.body.data)
        this.patchReward(data.body.data)

      })
    }
    else {
      this.httpService.post("points_reward/", settings).subscribe((data: any) => {
        if (data?.status === 200) {
          if (this.selectedFile) {
            const fd = new FormData();
            fd.append('image', this.selectedFile)
            this.httpService.post("points_reward/" + data?.body?.data?.id, fd).subscribe((data: any) => {
              if (data?.status === 200) {
                this.submit = false
                this.reward_form.reset()
                this.selectedFile = null
                this.messageService.add({ severity: 'success', summary: '', detail: 'Ajout effectué avec succès' });
              }
            })
          } else {
            this.submit = false
            this.reward_form.reset()
            this.selectedFile = null
            this.messageService.add({ severity: 'success', summary: '', detail: 'Ajout effectué avec succès' });
            this.router.navigate(['/rewards/rewards'])
          }
          this.onConfirm.emit(data.body.data);
        }
      })
    }
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  getRegions() {
    this.httpService.get('regions').subscribe((res: any) => {
      if (res?.status == 200) {
        this.regions = res.body.data
      }
    })
  }
  temporel_rewarding(event: any) {
    console.log(event);
    event.checked == true ? this.temporel_reward = true : ""

  }
  getCategories() {
    this.httpService.get('points_rewards_categories').subscribe((res: any) => {
      if (res?.status == 200) {
        this.categories = res.body.data
      }
    })
  }

  onTranslationsChange(form: FormGroup, key: string, lang: string, event: any) {
    var element: FormArray = form.get('translations') as FormArray;
    element = this.sharedService.onTranslationsChange(form, key, lang, event);
  }

  patchTranslationsData(form: FormGroup, data: any) {
    var element: FormArray = form.get('translations') as FormArray;
    element = this.sharedService.patchTranslationsData(form, data);
  }
}
