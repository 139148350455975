<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-3">
    <span class="avenirRegular f72 main-color font-weight-bold">Banners</span>
    <div class="d-flex">
      <div class="pr-3">
        <p-dropdown [options]="regions" placeholder="Région" [(ngModel)]="region"></p-dropdown>
      </div>
      <div class="pr-3">
        <p-dropdown [options]="status" placeholder="Statut" [(ngModel)]="expiration_status"></p-dropdown>
      </div>
      <div class="pr-3">
        <p-dropdown [options]="categories" placeholder="Catégories" [(ngModel)]="category"></p-dropdown>
      </div>
      <button class="btn avenirRegular f20 br25 green-back text-white px-4 mr-3" (click)="filter()">Filtrer
      </button>
      <button class="btn avenirRegular f20 br25 white-back f16 main-color px-4" (click)="resetFilter()">Réintianiliser
      </button>
      <button class="btn avenirHeavy f20 br25 green-back text-white px-4 ml-auto " (click)="addBanner()">Créer un
        banner</button>
    </div>
  </div>
  <ng-container *ngFor="let region of all_regions">
    <div class="pb-3" *ngIf="offers[region.label]">
      <div class="white-back py-1 px-4">
        <span class="f36 avenirBlack main-color">{{region.label}}</span>
      </div>
      <p-table [value]="offers[region.label]" [reorderableColumns]="true" responsiveLayout="scroll">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3rem"></th>
            <th style="width: 2%"></th>
            <th>Nom</th>
            <th>Région</th>
            <th>Catégorie</th>
            <th>Date de debut</th>
            <th>Date d'expiration</th>
            <th>Opérations</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
          <tr [pReorderableRow]="index">
            <td>
              <img src="../../../../../../assets/images/svg/Group380.svg" (dragend)="changeOrder()"
                [pReorderableRowHandle]="index" alt="" />
            </td>
            <td>
              <img *ngIf="rowData?.big_picture_url; else noImg" src="{{ rowData?.big_picture_url }}" class="img"
                alt="" />
            </td>
            <td class="f16 avenirRegular black">
              {{ rowData.name }}
            </td>
            <td class="f16 avenirRegular black">
              {{ rowData.region?.name }}
            </td>
            <td class="f16 avenirRegular black">
              {{ rowData.category }}
            </td>
            <td class="f16 avenirRegular black">
              {{ rowData.starts_at.date|date :'dd/MM/yyyy' }}
            </td>
            <td class="f16 avenirRegular black">
              {{ rowData.expires_at.date|date :'dd/MM/yyyy' }}
            </td>
            <td>
              <button class="btn p-0 mr-2" (click)="editBanner(rowData)">
                <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
  <div class=" w-100 ">
    <div class="d-flex justify-content-end">
      <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
        [showFirstLastIcon]="true" [totalRecords]="brodcasts?.meta?.pagination.total_pages" #paginator
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #noItems>
  <div class="text-center black avenirBlack f18 p-3">
    Cette section n'a pas encore des produits appuyez sur "Ajouter" pour en
    ajouter un
  </div>
</ng-template>
<ng-template #noImg>
  <img class="rewardImg" src="../../../../assets/images/png/Frame 1322.png" alt="">

</ng-template>
<p-sidebar #sidebar *ngIf="isLoading" [(visible)]="display" [appendTo]="sidebar" position="right"
  [style]="{width:'575px'}" [showCloseIcon]=false [baseZIndex]="10000" styleClass="disable-scroll">
  <div class="p-3">
    <div class="d-flex align-items-center pb-5">
      <button class="btn p-0" (click)="display=false">
        <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
      </button>
      <span class="f40 avenirRegular main-color fw85">{{edit?'Modifier le banner':'Créer un banner'}} </span>
    </div>
    <div class="d-flex justify-content-center pb-5">
      <div class="position-relative coverPhoto">
        <label class="customUpload flex-column" style="background-image:url('{{imgUrl}}')">
          <input type="file" (change)="showPreviewImage($event)" />
          <img src="../../../../../assets/images/svg/camera1.svg" class="cameraImg" alt="">
          <span class="f16 avenirHeavy deepGrey mt-2">Ajouter une image </span>
        </label>

      </div>
    </div>
    <form [formGroup]="offer_form" autocomplete="off">
      <div class="form-group mb-3">
        <input type="text" formControlName="name" class="form-control mdStl avenirRegular f16" id="name"
          placeholder="Nom de banner" [ngClass]="{'is-invalid': submit &&f['name'].errors}">
        <small *ngIf="submit &&f['name']?.errors?.['maxlength']" class="form-text red-color avenirRegular">Vous avez
          dépassé la taille autorisé </small>
        <small *ngIf="submit &&f['name']?.errors?.['required']" class="form-text red-color avenirRegular">Le nom du
          banner est obligatoire </small>
      </div>
      <div class="form-group mt-4 d-flex justify-content-between"
        *ngIf="home.user.type==='superadmin' ||home.user.profile?.all_regions===1">
        <span class="avenirMedium f18 ursopct black"> Toutes les régions</span>
        <p-inputSwitch formControlName="all_regions"></p-inputSwitch>
      </div>
      <div class="form-group mb-3" *ngIf="offer_form.get('all_regions')?.value===false">
        <p-dropdown optionValue="value" formControlName="region_id" [options]="regions" optionLabel="label"
          placeholder="Région" [showClear]="false" (onChange)="change_Region($event)"
          [ngClass]="{'is-invalid': submit &&f['region_id'].errors}">
        </p-dropdown>
      </div>
      <div class="form-group mb-3">
        <p-dropdown optionValue="value" formControlName="category" [options]="categories" optionLabel="label"
          placeholder="Toutes les catégories" [showClear]="false"
          [ngClass]="{'is-invalid': submit &&f['category'].errors}">
        </p-dropdown>
        <small *ngIf="submit &&f['category']?.errors?.['maxlength']" class="form-text red-color avenirRegular">Vous
          avez
          dépassé la taille autorisé </small>
        <small *ngIf="submit &&f['category']?.errors?.['required']" class="form-text red-color avenirRegular">La
          selection de catégorie est obligatoire </small>
      </div>
      <!-- <div class="form-group mb-3">
        <p-dropdown [options]="stores" formControlName="store" optionValue="id" optionLabel="name"
          [ngClass]="{'is-invalid': submit &&f['store'].errors}" [filter]="true" placeholder="Tout les Établissements"
          [showClear]="true" (onChange)="changeUrl()"></p-dropdown>
      </div> -->
      <div class="form-group mb-3">
        <p-dropdown [(ngModel)]="urlType" [ngModelOptions]="{standalone:true}" appendTo="body" [options]='urlSelector'
          optionValue="value" optionLabel="label" placeholder="Type de l'url" [showClear]="false"
          (onChange)="changeUrl()">
        </p-dropdown>
      </div>
      <div [ngSwitch]="urlType">
        <div *ngSwitchCase="'store'">
          <div class="form-group mb-3">
            <p-dropdown [options]="stores" formControlName="store" optionValue="id" optionLabel="name"
              [ngClass]="{'is-invalid': submit &&f['store'].errors}" [filter]="true"
              placeholder="Tout les Établissements" [showClear]="true" (onChange)="changeUrl()"></p-dropdown>
          </div>
        </div>
        <div *ngSwitchCase="'rewards'">
          <div class="form-group mb-3">
            <p-dropdown [options]="rewards" formControlName="rewards" optionValue="id" optionLabel="label"
              [ngClass]="{'is-invalid': submit &&f['rewards'].errors}" [filter]="true" placeholder="Tout les Rewards"
              [showClear]="true" (onChange)="changeUrl()"></p-dropdown>
          </div>
        </div>
        <div *ngSwitchCase="'category'">
          <div class="form-group mb-3">
            <p-dropdown [options]="search_categories" formControlName="search_category" optionValue="label"
              optionLabel="value" [ngClass]="{'is-invalid': submit &&f['store'].errors}"
              placeholder="Selectionner une  catégorie" [showClear]="true" (onChange)="changeUrl()"></p-dropdown>
          </div>
        </div>
        <!-- <div *ngSwitchDefault>output2</div> -->
      </div>
      <div class="form-group mb-3">
        <input type="text" formControlName="url" class="form-control mdStl avenirRegular f16" id="name"
          placeholder="https://www.example.com" [ngClass]="{'is-invalid': submit &&f['url'].errors}">
        <small *ngIf="submit &&f['url']?.errors?.['maxlength']" class="form-text red-color avenirRegular">Vous avez
          dépassé la taille autorisé </small>
      </div>
      <div class="row no-gutters align-items-center">
        <div class="form-group mb-3 col-md-5">
          <p-calendar inputId="basic" formControlName="starts_at"
            [ngClass]="{'is-invalid': submit &&f['starts_at'].errors}" dateFormat="dd/mm/yy" appendTo="body"
            class="avenirRegular" placeholder="JJ/MM/AA"></p-calendar>
          <small *ngIf="submit &&f['starts_at']?.errors?.['required']" class="form-text red-color avenirRegular">Vous
            avez dépassé la taille autorisé </small>
        </div>
        <div class="col-md-2 text-center">
          <span class="black avenirRegular f18 text-center">Au</span>
        </div>
        <div class="form-group mb-3 col-md-5">
          <p-calendar inputId="basic" formControlName="expires_at"
            [ngClass]="{'is-invalid': submit &&f['expires_at'].errors}" dateFormat="dd/mm/yy" appendTo="body"
            class="avenirRegular" placeholder="JJ/MM/AA"></p-calendar>
        </div>
      </div>
      <div class="d-flex justify-content-end  align-items-center mt-4">
        <button class="btn avenirRegular f18 text-white mr-3" *ngIf="edit" (click)="delete_notification(banner)"><img
            src="../../../assets/images/svg/delete..svg" alt=""></button>
        <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="display=false">Annuler
        </button>
        <button class="btn green-back  avenirRegular f16 text-white px-4"
          (click)="manageBanner()">{{edit?"Modifier":"Ajouter"}} </button>
      </div>
    </form>
  </div>
</p-sidebar>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="banner?.name"></app-confirmation>
