<form [formGroup]="notification_form" autocomplete="off">
  <div class="row no-gutters">
    <div class="col-md-6">
      <div class="pb-4">
        <span class="fw700 avenirRegular f25 main-color">Informations</span>
      </div>
      <div class="d-flex align-items-end mb-4">
        <label class="customUpload flex-column p-5 mr-3"
          [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
          <input type="file" (change)="showPreviewImage($event)" />
          <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
        </label>
        <input type="text" formControlName="title" class="form-control mdStl black avenirRegular f18" id="title"
          placeholder="Title" [ngClass]="{'is-invalid': f['title'].errors&&submit}">
      </div>
      <div class="form-group">
        <textarea class="form-control mdStl black avenirRegular f18" formControlName="body"
          [ngClass]="{'is-invalid': f['body'].errors&&submit}" placeholder="Description" id="message"
          rows="3"></textarea>
      </div>
      <div class="form-group">
        <input type="text" class="form-control mdStl black avenirRegular f18" formControlName="name" id="title"
          [ngClass]="{'is-invalid': f['name'].errors&&submit}" placeholder="Nom">
      </div>
      <div class="form-group mt-5">
        <p-dropdown [(ngModel)]="urlType" [ngModelOptions]="{standalone:true}" appendTo="body" [options]='urlSelector'
          optionValue="value" optionLabel="label" placeholder="Type de l'url" [showClear]="false"
          (onChange)="changeUrl()">
        </p-dropdown>
        <div [ngSwitch]="urlType" class="mt-3">
          <div *ngSwitchCase="'externLink'">
            <input type="text" formControlName="url" class="form-control mdStl avenirRegular f16" id="name"
              placeholder="https://www.example.com" [ngClass]="{'is-invalid': submit &&f['url'].errors}">
          </div>
          <div *ngSwitchCase="'store'">
            <div class="form-group mb-3">
              <p-dropdown [options]="stores" optionValue="id" optionLabel="name" [filter]="true"
                placeholder="Tout les Établissements" formControlName="store" [showClear]="true"
                (onChange)="changeUrl()"></p-dropdown>
            </div>
          </div>
          <div *ngSwitchCase="'rewards'">
            <div class="form-group mb-3">
              <p-dropdown [options]="rewards" optionValue="id" optionLabel="name" formControlName="rewards"
                [filter]="true" placeholder="Tout les Rewards" [showClear]="true" (onChange)="changeUrl()"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4">
        <span class="fw700 avenirRegular f25 main-color">Récepteur</span>
        <div *ngIf="!edit" class="d-flex align-items-center">
          <span class="avenirArabic f18 black mr-3">Multiple regions</span>
          <p-inputSwitch [(ngModel)]="multiple_region" [ngModelOptions]="{standalone:true}" (onChange)="multiple_region? notification_form.patchValue({hub_id: null}) : ''"></p-inputSwitch>
        </div>
      </div>
      <div *ngIf="!multiple_region;else select_region">
        <div class="form-group row align-items-center pb-2">
          <label for="inputEmail3" class="col-md-2 col-form-label avenirArabic f16 black">Destinataire</label>
          <div class="col-md-10 d-flex">
            <p-dropdown optionValue="id" (onChange)="select_hub($event.value)" formControlName="region_id"
              [ngClass]="{'is-invalid': f['region_id'].errors&&submit}" [options]="regions" optionLabel="name"
              placeholder="Régions" class="w-50" [showClear]="false">
            </p-dropdown>
            <p-dropdown optionValue="id" [options]="hubs" optionLabel="name" formControlName="hub_id"
              [ngClass]="{'is-invalid': f['hub_id'].errors&&submit}" placeholder="Hub" class="w-50" [showClear]="false">
            </p-dropdown>

          </div>
        </div>
      </div>
      <ng-template #select_region>
        <div class="form-group d-flex">
          <label for="inputEmail3" class="col-md-2 col-form-label avenirArabic f16 black">Régions</label>
          <p-multiSelect *ngIf="regions" class="w-100" [options]="regions" appendTo="body" [filter]="true"
            filterBy="name" placeholder="Selectionner les régions" optionLabel="name" optionValue="id"
            [(ngModel)]="selected_regions" [ngModelOptions]="{standalone:true}" [maxSelectedLabels]="6"
            selectedItemsLabel="{0} région selectionnée"></p-multiSelect>
        </div>
      </ng-template>
      <div class="pb-4">
        <span class="fw700 avenirRegular f25 main-color">La planification de la notification</span>
      </div>
      <div class="form-group mb-4">
        <p-calendar inputId="basic" formControlName="send_at" [showTime]="true" dateFormat="dd/mm/yy"
          [ngClass]="{'is-invalid': f['send_at'].errors&&submit}" appendTo="body" class="avenirMedium"
          placeholder="JJ/MM/AA"></p-calendar>
      </div>
      <div class="pb-4">
        <span class="fw700 avenirRegular f25 main-color">Test de notification</span>
      </div>
      <form [formGroup]="testing_form">
        <div class="form-group mb-5 d-flex align-items-center">
          <input type="text" class="form-control mdStl black avenirRegular f18" id="title"
            placeholder="Saisir le numéro" formControlName="phone"
            [ngClass]="{'is-invalid': g['phone'].errors&&submit_test}">
          <button class="btn main-color f18 avenirRegular" (click)='test()'>Tester</button>
        </div>
      </form>
      <div class="d-flex justify-content-end">
        <button class="btn avenirMedium f16 text-white dp-grey-back mr-3 px-4" (click)="cancel()">Annuler
        </button>
        <button class="btn green-back  avenirMedium f16 text-white px-4"
          (click)="manageNotifications()" [disabled]="is_disabled">Envoyer</button>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-center">
      <div class="position-relative">
        <img src="../../../../../assets/images/png/phone.png" alt="">
        <div class="position-absolute ctr w-100">
          <div class="noti py-2">
            <div class="d-flex align-items-center justify-content-between px-2 ">
              <div class="d-flex align-items-center">
                <img *ngIf="notification_form.get('large_icon')?.value;else foodbeeper"
                  src="{{ notification_form.get('large_icon')?.value }}" class="iconImg mr-1" alt="">
                <ng-template #foodbeeper>
                  <img src="../../../../../assets/images/svg/icon.svg" class="iconImg mr-1" alt="">
                </ng-template>
                <span class="avenirRegular f12 black ">{{notification_form.get('name')?.value||
                  "Nom de notification"}}</span>
              </div>
              <span class="avenirRegular f12 black ">1h ago</span>
            </div>
            <img *ngIf="imgUrl" src="{{imgUrl}}" class="w-100 pushImg pt-2" alt="">
            <div class="pt-2 px-2">
              <span class="avenirRegular f14 black fw700">{{notification_form.get('title')?.value||"Title"}}</span>
            </div>
            <div class="pt-2 px-2">
              <p class="f14 black avenirRegular">{{notification_form.get('body')?.value||"Contenu"}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
