import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { SpecialOpeningHoursComponent } from "./special-opening-hours/special-opening-hours.component";
@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {
  openingHoursForm!: FormGroup;
  openingHours: any
  items: any;
  date: any
  storeId: any
  submit: boolean = false;
  control: any
  day: any
  defaultDate: any
  defaultDate2: any
  stores: any=[]
  clone_display_Modal: boolean = false
  store_id: any
  @ViewChild('special_opening_hours') special_opening_hours!: SpecialOpeningHoursComponent;
  opening_hours: any
  constructor(private route: ActivatedRoute, private httpService: HttpService, private fb: FormBuilder, private messageService: MessageService) { }
  ngOnInit(): void {
    this.defaultDate = new Date("2021-01-01 08:00:00")
    this.defaultDate2 = new Date("2021-01-01 22:00:00")
    this.route.parent?.params.subscribe((params: any) => {
      this.storeId = params.id
      this.getOpeningHours(this.storeId);
    });
    this.get_stores(1)
  }
  get f() {
    let control = this.openingHoursForm?.get('dates.options') as FormArray;
    return control.controls;
  }
  get_stores(page:number) {
    this.httpService.get('stores').subscribe(data => {
      if (data?.status == 200) {
        data.body.data.forEach((element:any) => {
        this.stores.push( element)
       });
       if (data.body.meta.pagination.total_pages>page) {
        this.get_stores(page++)
       }
      }
    })
  }
  getOpeningHours(store_id: any) {
    this.httpService.get("store_opening_hours/" + store_id).subscribe(data => {
      if (data?.status === 200) {
        let array = new Array();
        this.opening_hours = data?.body.data
        if (data.body.data.length > 0) {
          data.body.data.sort((a: any, b: any) => {
            if (a.day < b.day) {
              return -1;
            } else if (a.day > b.day) {
              return 1;
            } else {
              return 0;
            }
          });
          data.body.data.forEach((item: any) => {
            if (item.date === null) {
              let second_array = item.hours.split(';');
              let first_array = new Array();
              let times = new Array();
              item.openings_hours = []
              for (let index = 0; index < second_array.length; index++) {
                first_array.push(new Date("01/01/2022 " + second_array[index]).getTime())
              }
              if (first_array.length > 1) {
                first_array.push(first_array[first_array.length - 1] + 1800000)
              }
              times = first_array.sort((a: any, b: any) => a - b)
              for (let index = 0; index < times.length - 1; index++) {
                var timeStart = times[index]
                var timeEnd = times[index + 1]
                var hourDiff = timeEnd - timeStart;
                if (hourDiff !== 1800000) {
                  times.splice(index, 0, times[index] + 1800000)
                  item.openings_hours.push({ start_at: new Date(times[0]).getHours() + ":" + String(new Date(times[0]).getMinutes()).padStart(2, "0"), finish_at: new Date(times[index]).getHours() + ":" + String(new Date(times[index]).getMinutes()).padStart(2, "0") })
                  times = [...times.slice(index + 2)]
                  index = 0
                }
                else {
                  if (index + 1 === times.length - 1) {
                    var indx = item.openings_hours.findIndex((x: any) => x.start_at == times[0] && x.finish_at === times[times.length - 1]);
                    indx === -1 ? item.openings_hours.push({ start_at: new Date(times[0]).getHours() + ":" + String(new Date(times[0]).getMinutes()).padStart(2, "0"), finish_at: new Date(times[times.length - 1]).getHours() + ":" + String(new Date(times[times.length - 1]).getMinutes()).padStart(2, "0") }) : ""
                  }
                }
              }
              item.hours.length > 0 ? item.open = true : item.open = false
              array.push(item)
            }
          });
        } else {
          for (let index = 0; index < 7; index++) {
            array.push({ day: index, open: false, openings_hours: [] })
          }
        }
        this.items = array;
        this.openingHoursForm = this.fb.group({
          dates: this.fb.group({
            options: this.fb.array([]),
          }),
        });
        this.control = <FormArray>this.openingHoursForm.get('dates.options');
        this.items.forEach((x: any) => {
          this.control.push(this.patchValues(x.day, x.openings_hours, x.open));
        });
      }
    })
  }
  patchValues(day: any, openings_hours: any, open: any) {
    return this.fb.group({
      day: [day],
      open: open,
      openings_hours: new FormArray(
        openings_hours.map((account: any) => this.createAccountControl(account))
      ),
    });
  }
  addOpeningHour(index: any): void {
    const control: any = this.openingHoursForm.get('dates.options');
    (<FormArray>control?.controls[index].controls.openings_hours).push(this.createAccountControl(""));
    this.items[index].openings_hours.push({ hours: '', start_at: '', finish_at: '' })
  }
  deleteOpeningHour(index: any, index2: any) {
    const control: any = this.openingHoursForm.get('dates.options');
    (<FormArray>control?.controls[index].controls.openings_hours).removeAt(index2);
    this.items[index].openings_hours.splice(index2, 1)
  }
  createAccountControl(initialValue: any): FormGroup {
    return this.fb.group({
      start_at: new FormControl(initialValue.start_at || "", [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0|3][0]$")]),
      finish_at: new FormControl(initialValue.finish_at || "", [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0|3][0]$")]),

    });
  }
  manageOpeningHour() {
    this.submit = true;
    if (!this.openingHoursForm.valid) {
      return
    }
    this.openingHoursForm.value.dates.options.forEach((element: any) => {
      let array = new Array();
      element.openings_hours.forEach((opening_hour: any) => {
        let start_at = new Date("01/01/2022 " + opening_hour.start_at).getTime()
        array.push(new Date(start_at).getHours() + ":" + String(new Date(start_at).getMinutes()).padStart(2, "0"));
        let finish_at = new Date("01/01/2022 " + opening_hour.finish_at).getTime()
        if (start_at > finish_at) {
          finish_at = finish_at + 86400000
        }
        finish_at = finish_at - 1800000
        for (let i = 0; i <= 47; i++) {
          if (start_at !== finish_at) {
            start_at = start_at + 1800000
            array.push(new Date(start_at).getHours() + ":" + String(new Date(start_at).getMinutes()).padStart(2, "0"))
          }

        }
      });
      let form = { day: element.day, hours: array.join(";") }
      this.httpService.post("store_opening_hours/" + this.storeId, form).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Modifcation effectuée avec succès' });
          this.submit = false;
        }
      })
    });
  }
  selectHour(event: any, index: number, index2: number, fc: any) {
    const control: any = this.openingHoursForm.get('dates.options');
    let date = new Date(event)
    var minutes = String(date.getMinutes()).padStart(2, "0")
    var hour = date.getHours();
    switch (fc) {
      case "start_at":
        (<FormArray>control?.controls[index].controls.openings_hours.controls[index2].patchValue({ start_at: hour + ":" + minutes }))
        break;
      case "finish_at":
        (<FormArray>control?.controls[index].controls.openings_hours.controls[index2].patchValue({ finish_at: hour + ":" + minutes }))
        break;
      default:
        break;
    }
  }
  add_special_date(item: any) {
    this.special_opening_hours.displayModal = true;
    let array = new Array();
    this.opening_hours.forEach((element: any) => {
      if (element.date !== null) {
        if (element.day === item.day) {
          array.push(element)
        }
      }
    });
    this.special_opening_hours.opening_hours = array;
    this.day = item.day;
    this.special_opening_hours.storeId = this.storeId;
    this.special_opening_hours.old_dates.setControl('dates', this.special_opening_hours.setExistingSkills(array));
  }
  changeDetector(item: any, i: number): void {
    if (item.open) {
      this.items[i].openings_hours.length = 0
      const control: any = this.openingHoursForm.get('dates.options');
      (<FormArray>control?.controls[i].controls.openings_hours).clear();
      item.open = false

    } else {
      item.open = true
      this.addOpeningHour(i)
    }
  }
  onConfirm(event: any) {
    this.getOpeningHours(this.storeId)
  }
  choose_store() {
    console.log(this.store_id);
    this.getOpeningHours(this.store_id)
    this.clone_display_Modal = false
  }

}
