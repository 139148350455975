import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-stories-management',
  templateUrl: './stories-management.component.html',
  styleUrls: ['./stories-management.component.scss']
})
export class StoriesManagementComponent implements OnInit {
  display: boolean = false
  story_form!: FormGroup
  search_form!: FormGroup
  submit: boolean = false
  imgUrl!: any;
  photo: any;
  selectedFile: any = null;
  rewards: any = []
  stores: any = []
  stories_partners: any = []
  sections: any = []
  types: any = [{ label: "Store", value: "store" }, { label: "Partenaire", value: "partner" }]
  urlSelector: any = [{ label: "None", value: "none" }, { label: "Lien externe", value: "externLink" }, { label: "Reward", value: "rewards" }, { label: "Section Menu", value: "section_menu" }, { label: "Créer une commande", value: "create_order" }, { label: "Profil établissement", value: "store_profile" }]
  visibility: any = [{ label: "Active", value: 1 }, { label: "Inactif", value: 0 }]
  urlType: any
  store_id!: any
  stories: any
  url: any
  edit: boolean = false
  isLoading: boolean = false
  section: any
  items: any
  selected_items: any
  story: any
  currentPage: any;
  page: number = 1
  regions: any = []
  selectedRegions: any = []
  region: any
  display_video: boolean = false
  loading: boolean = false;
  all_regions: any
  media: any
  media_type: any
  @ViewChild('deleteConfirmation') deleteConfirmation!: ConfirmationComponent
  constructor(private messageService: MessageService, private router: Router, private http: HttpService, private route: ActivatedRoute, private fb: FormBuilder, private datePipe: DatePipe) { }
  StoreTypeRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('type').value === 'store') {
      return Validators.required(formControl);
    }
    return null;
  }
  partnerTypeRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('type').value === 'partner') {
      return Validators.required(formControl);
    }
    return null;
  }
  ngOnInit(): void {
    this.search_form = this.fb.group({
      store: new FormControl("",),
      partner: new FormControl("",),
      active: new FormControl("",),
      region: new FormControl("",),
    })
    this.story_form = this.fb.group({
      type: new FormControl("", [Validators.required,]),
      store_id: new FormControl("", [this.StoreTypeRequiredValidator]),
      stories_partner_id: new FormControl("", [this.partnerTypeRequiredValidator]),
      name: new FormControl("", [Validators.required, Validators.maxLength(256)]),
      text: new FormControl("", Validators.maxLength(32)),
      expires_at: new FormControl(""),
      starts_at: new FormControl(""),
      regions: new FormControl([]),
      action: new FormControl("", Validators.maxLength(256)),
      description: new FormControl("", Validators.maxLength(2048)),
      title: new FormControl("", Validators.maxLength(256)),
      media: new FormControl("", Validators.required),
    }, {
      validators: [this.StoreTypeRequiredValidator, this.partnerTypeRequiredValidator]
    });
    this.story_form.get('type')?.valueChanges.subscribe(value => {
      this.story_form.get('store_id')?.updateValueAndValidity();
      this.story_form.get('stories_partner_id')?.updateValueAndValidity();
    });
    this.route.queryParamMap.subscribe(param => {
      Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
      let url: any = 'stories?order_by=-created&page=' + this.page
      if (param.get('store') || param.get('partner') || param.get('active')) {
        url += '&filters='
        this.search_form.patchValue({
          store: param?.get('store') ? JSON.parse(param?.get('store') || '') : "",
          partner: param?.get('partner') ? JSON.parse(param?.get('partner') || '') : "",
          active: param?.get('active') ? JSON.parse(param?.get('active') || '') : "",
        })
      }
      param.get('store') ? url += 'store:' + param.get('store') + ";" : ""
      param.get('partner') ? url += 'partner:' + param.get('partner') + ";" : ""
      param.get('active') ? url += 'active:' + param.get('active') + ";" : ""
      if (param.get('region')) {
        url += '&region=' + param?.get('region')
        this.search_form.patchValue({
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
        })
      }
      this.get_stories(url)
    })
    this.getRewards(1)
    this.get_regions()
    this.get_stories_partners(1)
    this.get_stores(1)
  }
  getRewards(page: any) {
    this.http.get("points_rewards?order_by=-created&page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          this.rewards.push(element)
        });
        if (data.body.meta.pagination.total_pages > page) {
          this.getRewards(page + 1)
        }

      }
    })

  }
  get_stores(page: any) {
    this.http.get("stores?order_by=-created&page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          this.stores.push({ value: element.id, label: element.name })
        });
        if (data.body.meta.pagination.total_pages > page) {
          this.get_stores(page + 1)
        }
      }
    })
  }
  get_stories_partners(page: any) {
    this.http.get("stories_partners?page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        this.stories_partners = data.body.data
      }
    })

  }
  get_section_menu(store_id: any) {
    this.sections = null
    this.http.get("menu/" + store_id + "/sections/").subscribe((data: any) => {
      if (data?.status == 200) {
        this.sections = data.body.data
      }
    })
  }

  get f() { return this.story_form.controls }
  get_stories(url: any) {
    this.http.get(url).subscribe(res => {
      if (res?.status === 200) {
        this.isLoading = true
        console.log(this.isLoading);

        this.stories = res.body
      }
    })
  }
  get_regions() {
    this.http.get("regions/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.regions = data.body.data
      }
    })
  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = event.target.files[0]
      this.story_form.patchValue({media:this.selectedFile})
      if (this.selectedFile.type.startsWith('video/')) {
        this.selectedFile.file_type = 'video'
      } else {
        this.selectedFile.file_type = 'image'
      }
    }
  }
  add_story() {
    this.display = true
    this.edit = false
    this.story_form.reset()
    this.url = null
    this.urlType = null
    this.selectedFile = null
    this.imgUrl = null;
    this.submit = false
  }
  edit_story(story: any) {
    this.selectedRegions = new Array()
    this.store_id = {
      id: story.owner.id,
      name: story.owner.name
    }
    if (story?.owner?.type === 'store') {
      this.get_section_menu(story.owner.id)
    }
    this.edit = true
    this.display = true
    this.story_form.reset()
    this.selectedFile = null
    this.imgUrl = null;
    this.urlType = null
    this.story = story
    this.story_form.patchValue({
      type: story.owner.type,
      store_id: {
        id: story.owner.id,
        name: story.owner.name
      },
      stories_partner_id: story.stories_partner_id,
      name: story.name,
      text: story.text,
      expires_at: story?.expires_at ? new Date(story?.expires_at?.date) : "",
      starts_at: story?.starts_at ? new Date(story?.starts_at?.date) : "",
      action: story.action,
      description: story.description,
      title: story.title,
      media: story.media,
    })
    let regions = new Array()
    regions = story.regions
    const nonEmptyRegions = regions.filter(region => region !== "");
    let selectedRegions: any = []
    if (nonEmptyRegions.length > 0) {
      if (regions[0] === "0") {
        console.log("this is all regions");
        this.all_regions = ['all_regions']
        this.regions.forEach((element: any) => {
          selectedRegions.push(element.id)
        });
      } else {
        nonEmptyRegions.forEach((element: any) => {
          selectedRegions.push(JSON.parse(element))
        });
      }
    }
    this.selectedRegions = selectedRegions
    this.imgUrl = story.media
    story?.action?.startsWith('https://') ? this.urlType = "externLink" : ""
    story?.action?.startsWith('/menu_section/') ? this.urlType = "section_menu" : ""
    story?.action?.startsWith('/rewards/') ? this.urlType = "rewards" : ""
    story?.action?.startsWith('create_order') ? this.urlType = "create_order" : ""
    story?.action?.startsWith('store') ? this.urlType = "store_profile" : ""
    this.url = story.action
  }
  manage_story() {
    this.submit = true

    if (this.story_form.invalid) {
      return
    }
    this.loading = true
    let url: any
    switch (this.urlType) {
      case "none":
        url = null
        break;
      case "rewards":
        url = this.url ? '/rewards/' + this.url : ''
        break;
      case "externLink":
        url = this.url
        break;
      case "section_menu":
        url = this.url ? '/menu_section/' + this.store_id.id + "/" + this.url : ''
        break;
      case "create_order":
        url = "/create_order/" + this.store_id.id + '/' + this.selected_items.join(',')
        break;
      case "store_profile":
        url = "/store/" + this.store_id.id
        break;
    }
    const fd = new FormData();
    fd.append("type", this.story_form.value.type);
    this.story_form.value.store_id ? fd.append("store_id", this.story_form.value.store_id.id) : ""
    this.story_form.value.stories_partner_id ? fd.append("stories_partner_id", this.story_form.value.stories_partner_id) : ""
    fd.append("name", this.story_form.value.name);
    if (this.selectedRegions?.length === this.regions?.length) {
      fd.append("regions[]", '0')
    } else {
      this.story_form.value?.regions?.forEach((element: any) => {
        fd.append("regions[]", element)
      });
    }
    this.story_form.value.description ? fd.append("description", this.story_form.value.description) : fd.append("description", "-")
    this.story_form.value.text ? fd.append("text", this.story_form.value.text) : fd.append("text", "-")
    this.story_form.value.title ? fd.append("title", this.story_form.value.title) : fd.append("title", "-")
    this.story_form.value.expires_at ? fd.append("expires_at", this.datePipe.transform(new Date(this.story_form.value.expires_at), 'YYYY-MM-dd') || '2000-01-01') : fd.append("expires_at", '2000-01-01')
    this.story_form.value.starts_at ? fd.append("starts_at", this.datePipe.transform(new Date(this.story_form.value.starts_at), 'YYYY-MM-dd') || '2000-01-01') : fd.append("starts_at", '2000-01-01')
    url ? fd.append("action", url) : fd.append("action", "-")
    fd.append("media", this.story_form.value.media)
    if (this.edit) {
      this.http.post("story/" + this.story.id, fd).subscribe(res => {
        if (res?.status === 200) {
          setTimeout(() => {
            this.loading = false
            this.display = false
            this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Story est modifiée avec succès', });
            Object.assign(this.story, res.body.data,)
          }, 500);
        }
      })
    } else {
      this.http.post("story/", fd).subscribe(res => {
        if (res?.status === 200) {
          setTimeout(() => {
            this.loading = false
            this.stories.data.unshift(res.body.data)
            this.display = false
            this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Story est ajoutée avec succès', });
          }, 500);
        }
      })
    }
  }
  create_order() {
    this.http.get('menu/' + this.store_id.id + '/' + this.section + '/items').subscribe(res => {
      if (res?.status == 200) {
        this.items = res.body.data
      }
    })
  }
  delete_story(story: any) {
    this.deleteConfirmation.displayModal = true;
    this.story = story;
  }
  confirmDelete(event: any) {
    this.http.delete("story/" + this.story.id).subscribe(data => {
      if (data?.status === 204) {
        this.stories.data = this.stories.data.filter((element: any) => element.id != this.story.id)
        this.deleteConfirmation.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Story ' + this.story.name + ' a été supprimé  avec succès' })
      }
    })

  }
  filter() {
    this.isLoading = false
    this.router.navigate(['/stories-management'], { queryParams: this.search_form.value, queryParamsHandling: 'merge' });
    console.log(this.isLoading);
  }
  reset_filter() {
    this.search_form.reset()
    this.router.navigate(['/stories-management'])
    this.isLoading = false
  }
  select_regions(event: any) {
    console.log(event);
    console.log(this.all_regions);
    let regions: any[] = []
    if (event.checked.length > 0) {
      this.regions.forEach((element: any) => {
        regions.push(element.id)
      });
      this.selectedRegions = regions
    }
    else {
      this.selectedRegions = []
    }
  }
  delete_media(){
    this.selectedFile = null
    this.imgUrl = null
    this.story_form.patchValue({media:null})
    this.display_video = false
  }
  play_video(media: any, type: any) {
    this.story = null
    this.display_video = true
    this.media = media
    this.media_type = type
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/stories-management'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  search(event: any) {
    this.http.get("search/autocomplete/" + event.query).subscribe(data => {
      this.stores = data.body.data;
    });
  }

}
