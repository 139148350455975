import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss']
})
export class PushComponent implements OnInit {
  selectedFile: any = null;
  imgUrl!: any[];
  notification_form!: FormGroup;
  testing_form!: FormGroup;
  image_form!: FormGroup;
  edit: boolean = false
  submit: boolean = false
  submit_test: boolean = false
  regions: any
  hubs: any[] = []
  notification_id: any
  notification: any
  selectedRegions: any
  store_id: any
  stores: any = []
  urlType: any
  rewards: any = []
  multiple_region: boolean = false
  selected_regions: any[] = new Array()
  is_disabled: boolean = false
  saved_image: any
  urlSelector: any = [{ label: "None", value: "none" }, { label: "Lien externe", value: "externLink" }, { label: "Etablissement", value: "store" }, { label: "Reward", value: "rewards" }]
  constructor(private app_config: AppConfigService, private fb: FormBuilder, private route: ActivatedRoute, private httpService: HttpService, private datePipe: DatePipe, private router: Router, private messageService: MessageService) { } ngOnInit(): void {

    this.route.parent?.params.subscribe((params: any) => {
      switch (params.id) {
        case 'create-broadcast':
          this.route.parent?.params.subscribe((param: any) => {
            if (param.store_id) {
              this.httpService.get("store/" + param.store_id).subscribe((res: any) => {
                if (res?.status == 200) {
                  this.urlType = "store"
                  this.select_hub(res.body.data.region.id)
                  this.notification_form.patchValue({
                    url: "store/" + res.body.data.id,
                    large_icon: res.body.data.logo.url,
                    region_id: res.body.data.region.id,
                    store: res.body.data.id
                  })
                  this.imgUrl = res.body.data.banner.url
                }
              })
            }
          })
          this.edit = false
          break;
        case 'duplicate-broadcast':
          this.route.queryParamMap.subscribe(param => {
            console.log(param.get('id'));
            this.getNotification(param?.get('id'))
            this.notification_id = param.get('id')
          })
          this.edit = false

          break;
        default:
          this.edit = true
          this.getNotification(params.id)
          this.notification_id = params.id
          break;
      }
      this.getRegions()
      this.get_stores(1)
      this.getRewards("points_rewards?order_by=-created&filters=active:1", 1)
    })
    this.notification_form = this.fb.group({
      type: new FormControl("image", [Validators.required]),
      image: new FormControl("", [Validators.required]),
    });
    this.testing_form = this.fb.group({
      phone: new FormControl("", [Validators.required]),
    });
    this.notification_form = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.maxLength(64)]),
      title: new FormControl("", [Validators.required, Validators.maxLength(32)]),
      body: new FormControl("", [Validators.required, Validators.maxLength(512)]),
      url: new FormControl("", Validators.maxLength(64)),
      large_icon: new FormControl("", Validators.maxLength(256)),
      big_picture: new FormControl("", Validators.maxLength(256)),
      send_at: new FormControl("",),
      region_id: new FormControl(""),
      hub_id: new FormControl(null,),
      store: new FormControl("",),
      rewards: new FormControl("",),
    });
  }
  get f() { return this.notification_form.controls }
  get g() { return this.testing_form.controls }
  getNotification(id: any) {
    this.httpService.get("notification_campaign/" + id).subscribe((data: any) => {
      if (data?.status === 200) {
        console.log(data.body.data);
        this.notification_form.patchValue({
          name: data.body.data.name,
          title: data.body.data.title,
          body: data.body.data.body,
          url: data.body.data.url,
          large_icon: data.body.data.large_icon,
          big_picture: data.body.data.big_picture,
          send_at: data.body.data.send_at.date,
          region_id: data.body.data.region_id,
          hub_id: data.body.data.hub_id,
        })
        this.imgUrl = data.body.data.big_picture
        this.notification = data.body.data
        data.body.data.region_id ? this.select_hub(data.body.data.region_id) : ""
      }
    })
  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
      this.saved_image = null
    }
  }
  getRegions() {
    this.httpService.get('regions').subscribe((res: any) => {
      if (res?.status == 200) {
        this.regions = res.body.data
      }
    })
  }

  select_store(event: any) {
    let result = this.stores.find((x: any) => x.id === event)
    this.notification_form.patchValue({
      url: "store/" + result.id,
      large_icon: result.logo.url
    })
    console.log(result);

    this.imgUrl = result.banner.url

  }
  select_hub(region: any) {
    this.httpService.get('hubs?filters=region:' + region).subscribe((res: any) => {
      if (res?.status == 200) {
        this.hubs = res.body.data
      }
    })
  }
  get_stores(page: number) {
    this.httpService.get('stores').subscribe((res: any) => {
      if (res?.status == 200) {
        res.body.data.forEach((element: any) => {
          this.stores.push(element)
        });
        if (res.body.meta.pagination.total_pages > page) {
          this.get_stores(page + 1)
        }
      }
    })
  }
  async manageNotifications() {
    await this.save_image()
    this.push_notifications()
  }
async save_image(){
   if (this.selectedFile && !this.saved_image) {
    const fd = new FormData();
    if (this.selectedFile) {
      fd.append('image', this.selectedFile)
      fd.append('type', "image")
    await  this.httpService.post('notification_campaigns/save_image', fd).toPromise().then((res: any) => {
        if (res?.status == 200) {
          this.notification_form.patchValue({
            big_picture: res.body.path,
          })
          this.saved_image = res.body.path
        }
      })
    }
   }
  }
  async push_notifications() {
    let form = this.notification_form.value
    if (this.notification_form.value.send_at) {
      let date = new Date(this.notification_form.value.send_at)
      form.send_at = this.datePipe.transform(date, 'YYYY-MM-dd HH:mm:ss')
    } else {
      delete form.send_at
    }
    if (!this.multiple_region) {
      this.selected_regions.length = 0
      form.region_id ? this.selected_regions = [form.region_id] : ""
    }
    if (this.edit) {
      await this.httpService.post('notification_campaign/' + this.notification_id, form).toPromise().then((res: any) => {
        if (res?.status == 200) {
          this.messageService.add({ severity: 'success', summary: '', detail: 'Notifcation modifié avec succès' });
          Object.assign(this.notification, res.body.data)
          this.is_disabled = false
        }
      })

    } else {
      this.selected_regions.forEach((element: any) => {
        let form_data = { ...form }
        form_data.region_id = element
        this.selected_regions.length > 0 ? form_data.region_id = element : delete form_data.region_id
        this.httpService.post('notification_campaign', form_data).subscribe((res: any) => {
          if (res?.status == 200) {
            this.messageService.add({ severity: 'success', summary: '', detail: 'Notifcation crée avec succès' });
            this.is_disabled = false
            this.router.navigate(['/notifications'])
          }
        })
      });
    }
  }

 async  test() {
    this.submit_test = true
    this.submit = true
    if (this.testing_form.invalid || this.notification_form.invalid) {
      return;
    }
    await  this.save_image()
    let data = { ...this.testing_form.value, ...this.notification_form.value }
    if (this.notification_form.value.send_at) {
      let date = new Date(this.notification_form.value.send_at)
      data.send_at = this.datePipe.transform(date, 'YYYY-MM-dd HH:mm:ss')
    } else {
      delete data.send_at
    }
    if (data.phone.lastIndexOf(this.app_config.config.phone_local_prefix, 0) === 0) {
      data.phone = data.phone.replace(this.app_config.config.phone_local_prefix, this.app_config.config.phone_int_prefix)
    }
    this.httpService.post('notification_campaigns/test', data).subscribe((res: any) => {
      if (res?.status === 200) {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Test envoyé avec succès' });
      }
    })

  }
  getRewards(url: any, page: any) {
    this.httpService.get(url + "&page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          this.rewards.push(element)
        });
        if (data.body.meta.pagination.total_pages > page) {
          this.getRewards(url, page + 1)
        }

      }
    })

  }
  changeUrl() {
    let url: any
    switch (this.urlType) {
      case "store":
        url = this.notification_form.get('store')?.value ? '/store/' + this.notification_form.get('store')?.value : ''
        break;
      case "rewards":
        url = this.notification_form.get('rewards')?.value ? '/rewards/' + this.notification_form.get('rewards')?.value : ''
        break;
      case "externLink":
        url = 'https://'
        break;
      default:
        url = ''
        break;
    }
    this.notification_form.patchValue({
      url: url
    })
  }
  cancel() {
    this.router.navigate(['/notifications'])
  }
}
