<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-5">
    <span class="avenirRegular f72 main-color font-weight-bold">Promo codes</span>
  </div>
  <div class="mb-5">
    <form autocomplete="off" [formGroup]="searchForm">
      <div class="d-flex align-items-center px-3 justify-content-between">
        <div class="d-flex align-items-center">
          <p-dropdown class="mr-4" [options]="expiration" optionValue="value" optionLabel="name"
            placeholder="Expiration" [showClear]="false" formControlName="expiration"></p-dropdown>
          <p-dropdown class="mr-4" [options]="promo_types" optionValue="value" optionLabel="name"
            placeholder="Type de promo" [showClear]="false" formControlName="promo_type"></p-dropdown>
          <p-dropdown class="mr-4" [options]="status" optionValue="value" optionLabel="name" placeholder="Status"
            [showClear]="false" formControlName="active"></p-dropdown>
          <button class="btn green-back h-100" (click)="collapse()" id="filter">
            <img src="../../../assets/images/svg/Vector 10.svg" alt="">
            <span class="ml-2 f18 text-white avenirRegular">Autre filter</span>
          </button>
        </div>
        <button
          *ngIf="home?.user?.profile?.permissions.includes('can_manage_offers_promos')||home?.user?.type==='superadmin'"
          class="btn avenirHeavy f20 br25 green-back text-white px-4"
          [routerLink]="['/promo_code/manage_promo_code/create_promo_code']">Créer un code promo</button>
      </div>
      <div class="green-back text-white" *ngIf="displayFilter">
        <div class="green-back text-white" *ngIf="displayFilter">
          <div class="row no-gutters align-items-center p-3">

            <div class="col-md-2 pr-2">
              <p-calendar inputId="basic" placeholder="JJ/MM/AA" appendTo="body" class="avenirMedium whiteBorder"
                formControlName="gte" dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="col-md-2 pr-2">
              <p-calendar inputId="basic" placeholder="JJ/MM/AA" appendTo="body" class="avenirMedium whiteBorder"
                formControlName="lte" dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="col-md-2 pr-2">
              <input type="text" class="form-control mdStl avenirMedium f18" id="code" formControlName="code"
                placeholder="Code">
            </div>
            <div class="col-md-2 pr-2">
              <input type="text" class="form-control mdStl avenirMedium f18" id="min_price" placeholder="Prix min"
                formControlName="min_price">
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-1 pr-1">
              <button class="w-100 text-white btn avenirRegular f16 main-color white-back"
                (click)="reset()">Réintianiliser</button>
            </div>
            <div class="col-md-1 pr-1">
              <button class="w-100 text-white btn avenirRegular f16 exportBtn" (click)="filter()">Filtrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive" *ngIf="promoCodes?.data.length>0;else noPrmoCode">
    <table class="table table-borderless customTable">
      <thead>
        <tr>
          <th>Code</th>
          <th>Description</th>
          <th>Crée le</th>
          <th>Remise</th>
          <th>Prix Min</th>
          <th>Expiration</th>
          <th>Régions</th>
          <th>Restaurants</th>
          <th>Utilisation</th>
          <th>Statistique</th>
          <th
            *ngIf="home?.user?.profile?.permissions.includes('can_manage_offers_promos')||home?.user?.type==='superadmin'">
            Opération</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let promo_code of promoCodes?.data" class="avenirRegular f18 black">
          <td>
            {{promo_code?.code}}
          </td>
          <td>
            {{promo_code?.description}}
          </td>
          <td>
            {{promo_code?.created_at?.date |date:"dd/MM/YYYY"}}
          </td>
          <td>
            {{promo_code?.value}}
          </td>
          <td>
            {{promo_code?.min_price}}
          </td>
          <td>
            {{promo_code?.expires_at?.date |date:"dd/MM/YYYY"}}
          </td>
          <td class="customTd">
            <span *ngFor="let item of promo_code.limited_regions;let isLast= last" class="text-capitalize">
              {{item}}{{isLast ? '' : ','}}
            </span>
          </td>
          <td>
            <span *ngFor="let item of promo_code.limited_stores;let isLast= last" class="text-capitalize">
              {{item}}{{isLast ? '' : ','}}
            </span>
          </td>
          <td>
            {{promo_code?.uses}}
          </td>
          <td>
            {{promo_code?.use_stats}}
          </td>
          <td
            *ngIf="home?.user?.profile?.permissions.includes('can_manage_offers_promos')||home?.user?.type==='superadmin'">
            <button class="btn p-0 mr-2" [routerLink]="['/promo_code/manage_promo_code/'+promo_code.code]">
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
            <button class="btn p-0 mr-2"(click)="show_modal(promo_code)" >
              <span class="pi pi-user"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="w-100 ">
      <div class="d-flex justify-content-end">
        <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
          [showFirstLastIcon]="true" [totalRecords]="promoCodes?.meta?.pagination?.total_pages" #paginator
          (onPageChange)="paginate($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #noPrmoCode>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Aucun résultat correspond à votre recherche </p>
  </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="apply_code_modal" [modal]="true" [style]="{width: '724px'}" [draggable]="false"
    [resizable]="false">
    <form [formGroup]="apply_codeForm" autocomplete="off">
      <div class="mb-4">
        <span class="avenirMedium f35 fw85 main-color">Appliquer la promo au client suivant: </span>
      </div>
      <input type="text" class="form-control  avenirMedium f18 mb-5" id="title" placeholder="Numéro de téléphone du client"
        formControlName="client_phone" [ngClass]="{'is-invalid': submit&&f['client_phone'].errors}">

      <div class="d-flex justify-content-end pt-3">
        <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4" (click)="apply_code_modal=false">Annuler </button>
        <button class="btn green-back avenirMedium f16  text-white px-4" (click)="apply_promo_code(apply_codeForm.value)">Ajouter</button>
      </div>
    </form>
</p-dialog>
