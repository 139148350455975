export const options ={
    "options": {
        plugins: {
          legend: {
            labels: {
              color: 'rgba(0, 0, 0, 1)',
              font: {
                size: 18,
                family: 'avenirMedium'
              },
              padding: 30,
              usePointStyle: true,
              boxWidth: 12
            },
            position: "top",
            align: "start"
          },
          tooltips: {
            mode: 'index',
            intersect: false
          }
        },
        scales: {
          x: {
            stacked: true,
            radius: 25,
            ticks: {
              color: '#00000066',
              font: {
                size: 14,
                family: 'avenirMedium'
              }
            },
            maxTicksLimit: 15,
            grid: {
              color: '#0000001a'
            }
          },
          y: {
            stacked: true,
            radius: 25,
            ticks: {
              color: '#00000066',
              maxTicksLimit: 10,
              font: {
                size: 14,
                family: 'avenirMedium'
              }
            },
            grid: {
              color: '#0000001a'
            }
          }
        }
      }
  
} 